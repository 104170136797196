import "./App.css";
import { GET_COMPONENT_TEXT_BY_COMPONENT_NAME } from "./graphql/ComponentTexts";
import { useQuery } from "@apollo/client";
import { Typography } from "@mui/material";
import { BackendUrl } from "./Constants";

export default function MainPage(){
    const pageName = 'main_page';
    const { loading, error, data } = useQuery(GET_COMPONENT_TEXT_BY_COMPONENT_NAME, {
      variables: {
          "filter": {
            "page": {
              "_eq": pageName
            }
          }
        },
    });

  if (loading) return null;
  if (error) return `Error! ${error}`;

  let firstPageComponent = data.component_texts.find(ct => ct.component_name === 'first_block');
  let secondPageComponent = data.component_texts.find(ct => ct.component_name === 'second_block');
  let listPageComponent = data.component_texts.find(ct => ct.component_name === 'list_block');

  return (
    <div className="main-page">
      <div class="pc-main-page d-none d-sm-none d-md-block">
        <div className="main-page-content">
          <div className="row">
            <div className="col">
              <Typography variant="body2" color="text.secondary">
                <div
                  className="content main-page-text"
                  dangerouslySetInnerHTML={{ __html: firstPageComponent.component_text }}>
                </div>
              </Typography>
            </div>
            <div className="col">
              <Typography variant="body2" color="text.secondary">
                  <div
                    className="content main-page-text"
                    dangerouslySetInnerHTML={{ __html: secondPageComponent.component_text }}>
                  </div>
                </Typography>
            </div>
          </div>
          <div className="row main-page-row">
            <div className="col">
            </div>
            <div className="col">
              <Typography variant="body2" color="text.secondary">
                <div
                  className="content main-page-list"
                  dangerouslySetInnerHTML={{ __html: listPageComponent.component_text }}>
                </div>
              </Typography>
            </div>
          </div>
        </div>
      </div>

      <div class="mobile-main-page d-block d-sm-block d-md-none">
        <div className="main-page-content">
          <div className="row">
            <Typography variant="body2" color="text.secondary">
              <div
                className="content main-page-text"
                dangerouslySetInnerHTML={{ __html: firstPageComponent.component_text }}>
              </div>
            </Typography>
          </div>
          <div className="row main-page-row">
          </div>
          <div className="row main-page-row">
              <Typography variant="body2" color="text.secondary">
                  <div
                    className="content main-page-text"
                    dangerouslySetInnerHTML={{ __html: secondPageComponent.component_text }}>
                  </div>
                </Typography>
          </div>
          <div className="row main-page-row mobile-main-page-last-row">
            <Typography variant="body2" color="text.secondary">
              <div
                className="content main-page-list"
                dangerouslySetInnerHTML={{ __html: listPageComponent.component_text }}>
              </div>
            </Typography>
          </div>
        </div>
      </div>
    </div>
  )
}